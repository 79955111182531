@import "../../../styles/helpers/functions";
@import "../../../styles/helpers/mixins";
@import "../../../styles/helpers/keyframes";
@import "../../../styles/settings/variables";
@import "../../../styles/settings/classes";
@import "../../../styles/settings/overrides";
@import "../../../styles/settings/fonts";


.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 220px;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(7, 7, 8, 0.2), 0px 1px 3px 1px rgba(7, 7, 8, 0.1);
  border-radius: get-border-radius(s);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slide-down-and-fade;
}

.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slide-left-and-fade;
}

.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slide-up-and-fade;
}

.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slide-right-and-fade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  @include inline-block;

  font-weight: 400;
  font-size: get-font(m);
  line-height: 20px;
  color: get-color('primary-text');
  align-items: center;
  padding: get-padding(s) get-padding(l);
  position: relative;
  user-select: none;
  outline: none;
  cursor: pointer;
}

.DropdownMenuItem a {
  text-decoration: none;
  color: get-color('primary-text');
}

.DropdownMenuItem:last-child {
  border-bottom: 0px;
}

.DropdownMenuTrigger[data-state='closed'] button {
  border: 2px solid transparent;
}

.DropdownMenuTrigger[data-state='open'] button {
  border: 2px solid get-color('primary');
}

.DropdownMenuSubTrigger[data-state='open'] {
  background-color: var(--violet4);
  color: var(--violet11);
}

.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--mauve8);
  pointer-events: none;
}

.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: rgba(get-color('primary'), 0.05);
}

.DropdownMenuLabel {
  padding: get-padding(m) 0 0 get-padding(l);
  font-size: get-font(s);
  line-height: 16px;
  font-weight: 600;
  color: get-color('text-secondary');
  text-transform: uppercase;
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: #E2E5EA;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  background-color: white;
  box-shadow: 0 2px 10px var(--blackA7);
}

.IconButton:hover {
  background-color: var(--violet3);
}

.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.RightSlot {
  @include inline-block;

  margin-left: auto;
  padding-left: 20px;
  color: inherit;
  align-items: center;

  svg {
    color: inherit;
  }
}

[data-disabled] .RightSlot {
  color: var(--mauve8);
}
